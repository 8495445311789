$font-main: "Helvetica Neue",Helvetica,Arial,sans-serif;
$font-condensed: "Helvetica Neue",Helvetica,Arial,sans-serif;
$font-heading: "Helvetica Neue",Helvetica,Arial,sans-serif;
$font-code: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
$font-pre: "Courier 10 Pitch", Courier, monospace;
$font-line-height-body: 1.5;
$font-line-height-pre: 1.6;

@mixin font-base-size() {

  @media (max-width: $break-xs) {
    @include font-size(1.8);
  }

  @media (min-width: $break-xs) {
    @include font-size(1.8);
  }

}

@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?qarsie");
  src: url("../fonts/icomoon.eot?qarsie#iefix") format("embedded-opentype"),
    url("../fonts/icomoon.ttf?qarsie") format("truetype"),
    url("../fonts/icomoon.woff?qarsie") format("woff"),
    url("../fonts/icomoon.svg?qarsie#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  // scss-lint:disable ImportantRule
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none;
  speak: none;

  /* Better Font Rendering =========== */
}

.icon-arrow:before {
  content: "\e900";
}

.icon-doc:before {
  content: "\e901";
}

$color-brand-red: #c00;
$color-brand-green: #0f8b8d;

$color-background-body: #fff;
$color-background-screen: #f1f1f1;
$color-background-hr: #beb7b3;
$color-background-button: #e6e6e6;
$color-background-pre: #eee;
$color-background-ins: #fff9c0;

$color-text-main: #141413;
$color-text-screen: $color-text-main;
$color-text-input: #666;
$color-text-input-focus: #111;
$color-link: $color-brand-green;
$color-link-visited: $color-brand-green;
$color-link-hover: #000;

$color-border-button: #ccc #ccc #bbb;
$color-border-button-hover: #ccc #bbb #aaa;
$color-border-button-focus: #aaa #bbb #bbb;
$color-border-input: #ccc;
$color-border-abbr: #666;

$color-main-theme: #0c548b;

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: .5em;
  clear: both;
  font-family: $font-heading;
  line-height: 1.5;
}

h1,
.h1 {
  @include font-size(3.5);
  margin: 0;
  font-weight: 300;
  line-height: 50px;

  &.lede {
    margin-top: .5em;
    padding-bottom: .5em;
    border-bottom: 9px #c00 solid;
    line-height: 39px;
  }
}

h2 {
  @include font-size(2.4);
  margin: .5em 0;
  font-weight: 600;
}

table {
  @include font-base-size();

  width: 100%;
  margin: 0 0 1.5em;

  th {
    padding: 10px 20px;
    @media (max-width: $break-sm) {
      padding: 5px;
    }
  }

  td {
    padding: 2px 20px;
    @media (max-width: $break-sm) {
      padding: 2px 15px;
    }
  }

}

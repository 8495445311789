/* Text meant only for screen readers. */
// scss-lint:disable ImportantRule
.screen-reader-text {

  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);

  &:hover,
  &:active,
  &:focus {

    @include font-size(.875);

    display: block;
    top: 5px;
    left: 5px;
    width: auto;
    height: auto;

    padding: 15px 23px 14px;
    border-radius: 3px;
    background-color: $color-background-screen;
    color: $color-text-screen;
    font-weight: bold;
    line-height: normal;
    text-decoration: none;

    box-shadow: 0 0 2px 2px rgba(0, 0, 0, .6);
    z-index: 100000; /* Above WP toolbar. */
    clip: auto !important;

  }
}


.text--dark-blue{
  color: #174d7f;
}
.text--light-blue{
  color: #1989c7;
}
strong, b{
  font-weight: bold;
}
.text--bold{
  font-weight: bold;
}
.top-bar{

  &__logos-container{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    padding: 35px 0;
    img{
      margin-bottom: 20px;
    }
    @include respond-to(xs) {
      justify-content: space-between;
      img{
        margin-bottom: 0;
      }
    }
  }
}
.results-page{
  .hero{
    &__sub-heading{
      margin-bottom: 0;
    }
  }
}

.banner-arrow__container {
  position: relative;
}

.banner-arrow{
  position: absolute;
  bottom: -120px;
  left: 50%;
  transform: translate(-50%,0);
  .pulsate-css {
    display: block;
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 1;
    width: 120px;
    height: 120px;
    border: 3px solid #017dc1;
    border-radius: 50%;
    z-index: 1;
  }
  img{
    position: relative;
    z-index: 2;
  }
}

.hero{

  &__content{
    position: relative;
    padding: 100px 0;
    margin-bottom: 120px;
    background:{
      image: url(/img/homeBanner1.png);
      size: cover;
      position: center;
    }
  }

  &__text-container{
    h1,p{
      color: white;
    }
  }

  &__heading{
    @include font-size(6.4);
    line-height: 70px;
    font-weight: bold;
  }

  &__sub-heading{
    @include font-size(2.7);
    line-height: 30px;
    margin-top: 30px;
    margin-bottom: 50px;
    >span{
      @include font-size(2.4);
    }
  }

  &__icons-container{
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
     -moz-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
        -webkit-align-items: center;
     -moz-align-items: center;
      -ms-align-items: center;
          align-items: center;
    -webkit-justify-content: flex-start;
     -moz-justify-content: flex-start;
      -ms-justify-content: flex-start;
          justify-content: flex-start;
            -ms-flex-pack: flex-start;

  }

  &__icon{
    display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
    -webkit-justify-content: center;
     -moz-justify-content: center;
      -ms-justify-content: center;
          justify-content: center;
            -ms-flex-pack: center;
    -webkit-align-items: center;
     -moz-align-items: center;
      -ms-align-items: center;
          align-items: center;

    width: 180px;
    height: 180px;

    >img{
      margin: 0;
    }
    >p{
      @include font-size(2.8);
      line-height: 32px;
      font-weight: 500;
      margin: 0;
    }
  }
}

div.content {
  overflow: hidden;
}

// introduction section
.section__introduction{
  padding-top: 160px;
}

.introduction{

  &__text {

    @include respond-to(xs) {
      margin-bottom: 0;
      @include font-size(2);
    }
  }

  &__container{
    position: relative;
    &:before,&:after{
      position: absolute;
      content: '';
    }
    @include respond-to(sm) {
      // &:before{
      //   height: calc(100% + 100px);
      //   width: 100%;
      //   background:{
      //     image: url('/img/light-off.png');
      //     size: contain;
      //     repeat: no-repeat;
      //   }
      //   left: 10px;
      //   bottom: 10px;
      // }
      &:after{
        height: calc(100% + 150px);
        width: 320px;
        background-image: url(/img/bc_map_no_light.png);
        background-size: contain;
        background-repeat: no-repeat;
        right: -148px;
        top: -91px;
      }
    }

    &.light-on {

      .introduction__wrapper {

        &:after, &:before {
          z-index: 3;
        }
      }
    }
  }

  &__wrapper{
    padding: 10px;
    @include respond-to(sm) {
      padding: 90px 180px 42px 90px;
    }
    border: 10px solid $color-main-theme;
    @include respond-to(sm) {

      // &:before{
      //   position: absolute;
      //   content: '';
      //   height: calc(100% + 100px);
      //   width: 100%;
      //   background:{
      //     image: url('/img/lamp.png');
      //     size: contain;
      //     repeat: no-repeat;
      //   }
      //   left: 10px;
      //   bottom: 10px;
      //   z-index: -1;
      // }

      // .lamp__container {
      //   position: absolute;
      //   content: '';
      //   height: calc(100% + 100px);
      //   width: 100%;
      //   // background-image: url(/img/lamp.png);
      //   background-size: contain;
      //   background-repeat: no-repeat;
      //   left: 10px;
      //   bottom: 10px;
      //   z-index: -1;

      //   &:before{
      //     position: absolute;
      //     content: '';
      //     height: calc(100% + 100px);
      //     width: 100%;
      //     background:{
      //       image: url('/img/lamp.png');
      //       size: contain;
      //       repeat: no-repeat;
      //     }
      //     left: 10px;
      //     bottom: 10px;
      //     z-index: -1;
      //   }
      // }
      // &:after{
      //   position: absolute;
      //   content: '';
      //   height: calc(100% + 150px);
      //   width: 320px;
      //   background-image: url(/img/BC_map.png);
      //   background-size: contain;
      //   background-repeat: no-repeat;
      //   right: -148px;
      //   top: -91px;
      //   z-index: -1;
      // }
    }
  }

  &__heading{
    @include font-size(3);
    line-height: 42px;
    color: $color-main-theme;
    font-weight: bold;
  }

  &__sub-heading{
    @include font-size(1.8);
    line-height: 30px;
  }
}

.section__divider{
  margin-top: 70px;
  padding-bottom: 130px;
  font-weight: bold;
  &#divider1{
    padding-bottom: 250px;
  }
  &-container{
    >p{
      margin-top: 0;
      margin-bottom: 50px;
      color: #4f4f4f;
      font-size: 24px;
    }
  }
  &-navigation{
    p{
      color: #017dc1;
      font-size: 36px;
    }
  }
}


// solution start section
.section__solution{
  position: relative;
  .cloud{
    display: none;
    position: absolute;
    @include respond-to(md) {
      display: block;
    }
    &#cloud1{
      top: -250px;
      left: -80px;
    }
    &#cloud2{
      top: -130px;
      right: 280px;
    }
    &#cloud3{
      top: 0;
      right: 0;
    }
    &#cloud4{
      left: 50px;
      bottom: 150px;
    }
    &#cloud5{
      bottom: 0;
      right: 100px;
    }
    &#cloud6{
      left: -25px;
      bottom: -40px;
    }
    &#cloud7{
      left: 560px;
      bottom: -100px;
    }

  }

  &__start{

    &--text-container{
      position: relative;
      &:before{
        content: '';
        position: absolute;
        width: 68px;
        height: 68px;
        background:{
          image: url('/img/Rectangle_image.png');
          size: auto;
          repeat: no-repeat;
        }
        top: 15px;
        left: -80px;
      }

      p{
        @include font-size(2.4);
        margin-bottom: 0;
      }
    }
  }

}
.nice-title-with-icon{
  @include font-size(3.5);
  line-height: 50px;
  @include respond-to(sm) {
    @include font-size(6.5);
    line-height: 70px;
  }
  color: #1875ba;
}

.custom-br{
  @media only screen and (max-width: 992px){
    display: none;
  }
}
// question list
.question-list{

  &__container{
    >.question-list{
        .question-list__item{
          padding-top: 100px;
          @include respond-to(sm) {
            padding-top: 0;
          }
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;

          p {
            flex: 1;
          }
        }
    }
  }

  &__heading{
    position: relative;
    padding-left: 60px;
    padding-bottom: 30px;
    @include respond-to(sm) {
      padding-left: 90px;
    }
    &:before{
      content: attr(data-question-num);
      position: absolute;
      left: 0;
      top: 0;
      @include font-size(5);
      @include respond-to(sm) {
        top: 12px;
        @include font-size(8.588);
      }
      line-height: 49.08px;
      color: #174d7f;
      font-weight: bold;
    }
    &:after{
      content: '';
      position: absolute;
      top: 36px;
      left: 32px;
      width: 7px;
      height: 7px;
      @include respond-to(sm) {
        top: 59px;
        left: 50px;
        width: 11px;
        height: 11px;
      }
      background-color: #174d7f;
    }
    h2,p{
      margin: 0;
      font-weight: bold;
    }
    h2{
      @include font-size(2);
      @include respond-to(sm) {
        @include font-size(2.6);
      }
      color: #1875ba;
    }
    p{
      @include font-size(1.8);
      @include respond-to(sm) {
        @include font-size(2);
      }
      color: #5ac399;
    }
  }

  &__choices-container{
    margin-top: 30px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    >.choice{
      opacity: 0;
      width: 100%;
      padding-left: 105px;
      padding-right: 10px;
      border: 3px solid lightgray;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      position: relative;
      min-height: 150px;
      background: rgba(255,255,255,.6);
      &:before{
        content: '';
        position: absolute;
        left: 10px;
        bottom: 0;
        width: 75px;
        height: 105px;
        background:{
          image: url('/img/light.png');
          size: auto;
          repeat: no-repeat;
        }
      }
      @include respond-to(sm) {
        overflow: hidden;
        min-height: inherit;
        padding-top: 40px;
        padding-bottom: 40px;
        &:before{
          bottom: -26px;
          width: 75px;
          height: 90%;
          background-image: url(/img/light.png);
          background-size: auto;
          background-repeat: no-repeat;
          background-position: top;
        }
      }
      &:hover{
        cursor: pointer;
      }
       &.active{
        border-color: #5ac399;
        background-color: #1875ba;
        color: white;
        cursor: pointer;
        &:before{
          background-image: url('/img/light_hover.png');
        }
      }
      @include respond-to(sm) {
        width: calc(50% - 15px);
      }
      p{
        @include font-size(1.6);
        line-height: 21px;
        margin: 0;
      }
    }
  }
}
.none-btn{
  color: #017dc1 !important;
  text-decoration: none !important;
  font-size: 36px;
  font-weight: bold;
  outline: none !important;
  &:hover,&:active, &:visited{
    color: #017dc1;
    text-decoration: none;
  }
}
.btn{

  &--next-section {
    cursor: pointer;
  }

  &--next-section:not(.none-btn):not(.nav-link){
    background-color: #ef962d;
    color: white;
    border-radius: 10px;
    margin-top: 5px;
    @include font-size(2.6);
    font-weight: bold;
    &:hover{
      background-color: #1875ba;
      color: white;
    }
    &:visited{
      color: white;
    }
  }
}

.nav-link {
  cursor: pointer;
}

.dot-nav{
  list-style-type: none;
  margin: 0;
  padding: 0;
  // width: 12px;
  position: fixed;
  top: 50%;
  right: -95px;
  transform: translate(0,-50%);
  opacity: 0;
  transition: all .6s ease-in-out;
  &.expand{
    @include respond-to(sm) {
      right: 0;
    }
  }
  &.active{
    opacity: 1;
  }
  >li{
    a.anchor-link,span{
      display: inline-block;
    }
    // width: 12px;
    height: 12px;
    &:not(:last-child){
      margin-bottom: 30px;
    }
    span{
      padding-right: 20px;
      padding-left: 5px;
      @include font-size(1.6);
    }
  }
  .anchor-link{
    width: 12px;
    height: 12px;
    border: 2px solid #1875ba;
    border-radius: 50%;
    outline: none;
    &.is-selected{
      background-color: #1875ba;
    }
  }
}
#quest-list__choices2{
  .choice{
    width: 100%;
    @include respond-to(sm) {
      width: calc(33.33% - 15px);
    }
  }
}

.questions-content{
  display: flex;
  flex-flow: row wrap;
  position: relative;
  &:before{
    @include respond-to(sm) {
      content: '';
      position: absolute;
      width: 740px;
      height: 100%;
      top: 0;
      left: 0;
      background-image: url(/img/map.jpg);
      background-size: contain;
      background-repeat: no-repeat;
    }
    @media only screen and (max-width: 1480px){
      opacity: .2;
    }
  }
  >div{
    width: 100%;
    @include respond-to(sm) {
      width: calc(100% - 752px);
      &.question-list__container{
        width: 752px;
        position: relative;
        left: 50px;
      }
    }
  }
}

#section3{
  @include respond-to(sm) {
    .question-list__item{
      // padding-top: 100px;
    }
  }
}


// form
.section__form{
  padding: 50px 0 320px;
  background:{
    image: url(/img/footer_background.png);
    repeat: no-repeat;
    size: contain;
    position: bottom;
  }
}
.form{
  &__container{
    h2{
      text-align: center;
      color: #174d7f;
      font-weight: bold;
      @include respond-to(sm) {
        @include font-size(4.588);
      }
    }

    .form-control{
      height: 40px;
      border-radius: 5px;
    }

    .result-label{
      color: #4f4f4f;
      font-weight: normal;
    }
  }
}

.btn-submit{
  display: block;
  width: 100%;
  color: white;
  background-color: #ef962d;
  font-weight: bold;
  padding: 10px 0;
  font-size: 20px;
  border-radius: 10px;
  margin-top: 50px;
  @include respond-to(sm) {
    font-size: 26px;
  }
  &:hover{
    color: white;
    background-color: #1875ba;
  }
}

.form-label{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: pointer;
}

.selected-choices__container{
  input{
    display: none;
  }
}
// end form

// footer
.footer{
  &__container{
    padding: 56px 0;
    text-align: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    @include respond-to(sm) {
      text-align: left;
      justify-content: space-between;
    }
    p{
      margin: 0;
    }
    div{
      margin-top: 30px;
      @include respond-to(sm) {
        margin-top: 0;
      }
    }
  }
}
// end footer


// results page
.results-page{
  .nav-tabs{
    padding-bottom: 25px;
    border-bottom: none;
    >li{
      &:not(.nav-item--get-started){
        width: 210px;
        @include respond-to(xs) {
          margin-right: 20px;
        }

        &:hover{
          background: transparent;
        }
        &.active{
          >a{
            color: #1989c7;
            border-bottom: 4px solid #1989c7;
          }
        }
        >a{
          color: #174d7f;
          padding-right: 0;
          padding-left: 0;
          padding-bottom: 15px;
          border: none;
          border-bottom: 4px solid lightgray;
        }
      }
      &.nav-item--get-started{
        text-decoration: none;
        &.active{
          >a{
            background-color: #1989c7;
            border: 1px solid #1989c7 !important;
          }
        }
        >a{
          background-color: #ef962d;
          color: white;
          border: 1px solid #ef962d !important;
          border-radius: 8px;
          &:hover{
            background-color: #1989c7;
            border: 1px solid #1989c7 !important;
          }
        }
      }
      >a{
        @include font-size(3);
        font-weight: bold;
        text-decoration: none;
        outline: none;
      }
    }
  }
  .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus{
    border: none;
    text-decoration: none;
    border-bottom: 4px solid #1989c7;
  }
  .nav > li > a:hover, .nav > li > a:focus {
    text-decoration: none;
    background-color: transparent;
  }
  .results{
    &__heading{
      @include font-size(4.8);
      font-weight: bold;
      margin-bottom: 0;
    }
    &__sub-heading{
      margin-top: 0;
      @include font-size(2.4);
    }
    &__list-item{

      &__heading{
        @include font-size(2);
        font-weight: bold;
        margin-bottom: 25px;
        @include respond-to(xs) {
          @include font-size(2.4);
        }
      }
    }
  }
  .heading--checkmark{
    position: relative;
    padding-left: 50px;
    >img{
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .results__list{
    >.results__list-item{
      &__row{
        padding: 40px 0;
        &:nth-child(odd){
          background: #f8f8f8;
        }
      }
    }
  }
  // result list 1
  #results-list1{
    p{
      margin: 0;
    }
  }
  // result list 2
  #results-list2{
    h3{
      @include font-size(3);
    }
    h4{
      font-weight: bold;
      @include font-size(2.4);
    }
    p,ul{
      margin: 0;
    }
    .results__list-item__row{
      >.container{
        display: flex;
        flex-flow: row wrap;
        >div{
          display: flex;
          &:first-child{
            flex-flow: row wrap;
            align-item: center;
          }
          &:last-child{
            flex-flow: column nowrap;
            justify-content: center;
          }
        }
      }
      .download-container{
        margin-top: 20px;
        @include respond-to(xs) {
          margin-top: 0;
        }
        >p{
          margin-bottom: 20px;
          padding-bottom: 20px;
          &:first-child{
            border-bottom: 1px solid #e7e7e7;
          }
          a{
            text-decoration: none;
            color: #141413;
            &:hover{
              color: #1989c7;
            }
          }
          img{
            margin-right: 20px;
          }
        }
      }
    }
  }

  .btn--regular{
    margin: 60px 0;
  }
}
#partners{
  ul.custom-list{
    &--green{
      >li{
        margin-bottom: 10px;
      }
    }
  }
}
ul{
  &.custom-list{
    &--green{
      padding-left: 0;
      list-style-type: none;
      >li{
        position: relative;
        padding-left: 10px;
        &:before{
          content: "";
          position: absolute;
          left: 0;
          top: 12px;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: #5ac399;
        }
      }
    }
    p{
      margin: 0;
    }
    &--number{
      margin-top: 0;
      >li{
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin-bottom: 30px;
        >div{
          margin-right: 20px;
          >p{
            width: 60px;
            height: 60px;
            background: #1989c7;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            @include font-size(3.4);
            font-weight: bold;
          }
        }
      }
    }
  }
}
.did-you-know__container{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  padding: 50px 0;
  margin-top: 50px;
  margin-bottom: 50px;
  h3,p{
    margin: 0;
    text-align: center;
    @include respond-to(xs) {
      text-align: left;
    }
  }
  h3{
    @include respond-to(xs) {
      margin-right: 50px;
    }
  }
  p{
    margin-top: 5px;
  }
  &#did-you-know__container2{
    h3,p{
      text-align: left;
    }
    h3{
      width: 300px;
    }
    flex-flow: row wrap;
    >div{
      >div{
        &:first-child{
          border-bottom: 1px solid #ebebeb;
        }
        margin: 10px 0;
        padding: 10px 0;
      }
    }
    @include respond-to(xs) {
      flex-flow: row nowrap;
    }
  }
}
a{
  &.text--light-blue{
    color: #1989c7;
  }
}
.cta-banner{
  padding: 80px 0;
  background-color: #1989c7;
  h2,p,a{
    color: white;
  }
  h2{
    @include font-size(4.5);
    margin-bottom: 70px;
  }
  p{
    font-weight: bold;
    color: #a7d5ef;
  }
  a{
    @include font-size(2.3);
    &.cta-btn__phone-number{
      text-decoration: none;
      @include font-size(3.4);
    }
  }
  >.container{
    @include respond-to(sm) {
      width: 990px;
    }
  }
  .cta-btn{
    border: 5px solid #5ac399;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    padding-left: 30px;
    transition: all .3s linear;
    @include respond-to(sm) {
      margin-bottom: 0;
    }
    @include respond-to(xs) {
      width: 450px;
      padding-left: 175px;
      &:before{
        content: '';
        bottom: 0;
        position: absolute;
        height: calc(100% + 18px);
        background:{
          size: contain;
          repeat: no-repeat;
        }
      }
      &__phone{
        margin-right: 30px;
        padding-left: 150px;
        &:before{
          left: 20px;
          width: 115px;
          background-image: url(/img/phoneIcon.png);
        }
      }
      &__email{
        &:before{
          bottom: 0;
          left: 0;
          width: 166px;
          background-image: url(/img/emailIcon.png);
        }
      }
    }
    &__container{
      display: flex;
      flex-flow: row wrap;
    }

    &:hover {
      background-color: #19699d;
    }
  }
}
#resultsTab{
  >li{
    margin-bottom: 20px;
  }
}
.btn--regular{
  background-color: #ef962d;
  color: white;
  border-radius: 10px;
  padding: 10px 20px;
  margin-top: 5px;
  font-size: 2.6rem;
  font-weight: bold;
  text-decoration: none !important;
  display: inline-block;
  &:hover,&:visited,&:active{
    color: white;
    text-decoration: none;
  }
  &:hover{
    background-color: #1989c7;
  }
}

.animated {
  opacity: 0;

  &.zoomIn {
    opacity: 1;
  }
}


#Table_01 {
  width: auto;
  margin: 0 auto;
  td,th,tr{
    vertical-align: inherit;
    padding: 0;
  }

  tr{
    padding: 0;
  }
}

.on-mobile {
  display: block;
  margin-bottom: 40px;

  @include respond-to(sm) {
    display: none;
  }
}

.on-desktop {
  display: none;
  margin-bottom: 40px;

  @include respond-to(sm) {
    display: block;
  }
}

.animated.delay-0-1s {
  animation-delay: .1s;
}
.scrollto-animated-element{
  opacity: 0;
}
.animated.delay-0-2s {
  animation-delay: .2s;
}
.animated.delay-0-3s {
  animation-delay: .3s;
}
.animated.delay-0-4s {
  animation-delay: .4s;
}
.animated.delay-0-5s {
    animation-delay: .5s;
}
.animated.delay-0-6s {
    animation-delay: .6s;
}
.animated.delay-0-7s {
    animation-delay: .7s;
}
.animated.delay-0-8s {
    animation-delay: .8s;
}
.animated.delay-0-9s {
    animation-delay: .9s;
}
.animated.delay-1-1s {
    animation-delay: 1.1s;
}
.animated.delay-1-2s {
    animation-delay: 1.2s;
}
.animated.delay-1-3s {
    animation-delay: 1.3s;
}
.animated.delay-1-4s {
    animation-delay: 1.4s;
}
.animated.delay-1-5s {
    animation-delay: 1.5s;
}
.animated.delay-1-6s {
    animation-delay: 1.6s;
}
.animated.delay-1-7s {
    animation-delay: 1.7s;
}
.animated.delay-1-8s {
    animation-delay: 1.8s;
}
.animated.delay-1-9s {
    animation-delay: 1.9s;
}
.animated.delay-2s {
    animation-delay: 2s;
}
.animated.delay-2-1s {
    animation-delay: 2.1s;
}
.animated.delay-2-2s {
    animation-delay: 2.2s;
}
.animated.delay-2-3s {
    animation-delay: 2.3s;
}
.animated.delay-2-4s {
    animation-delay: 2.4s;
}
.animated.delay-2-5s {
    animation-delay: 2.5s;
}
.animated.delay-2-6s {
    animation-delay: 2.6s;
}
.animated.delay-2-7s {
    animation-delay: 2.7s;
}
.animated.delay-2-8s {
    animation-delay: 2.8s;
}
.animated.delay-2-9s {
    animation-delay: 2.9s;
}

.lamp__container {
  position: absolute;
  left: 0;
  height: calc(100% + 30px);
  bottom: 10px;
  z-index: 100;
  width: 100%;

  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.lamps {
  position: relative;
  height: 100%;
  left: 40px;
  bottom: 10px;

  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.lamp {
  position: absolute;
  height: 100%;

  img {
    width: auto;
    display: none;
    @include respond-to(xs) {
      height: 572px;
    }

    @include respond-to(sm) {
      display: block;
    }

    @include respond-to(md) {
      height: 458px;
    }
  }
}

.lamp-on {
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: all .3s ease-in;

  img {

    @include respond-to(xs) {
      height: 572px;
    }

    @include respond-to(md) {
      height: 458px;
    }
  }

  &.active {
    height: 100%;
  }

  .overlay {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;

  }
}

.bc-map-overlay {
  position: absolute;
  content: '';
  height: 0;
  width: 320px;
  right: -148px;
  top: -91px;
  overflow: hidden;
  z-index: 3;
  // transition: all .6s ease-in;
  &::before{
    position: absolute;
    content: '';
    width: 320px;
    height: 578px;
    background-image: url(/img/BC_map.png);
    background-size: contain;
    background-repeat: no-repeat;
    left: 0;
    top: 0;
    z-index: 3;
  }

  &.active {
    height: calc(100% + 150px);
  }
}


// sticky nav
.sticky-nav {

  @include respond-to(sm) {

    &.sticky {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      background: white;
      z-index: 100;
      box-shadow: 1px 1px 6px rgba(0, 0, 0, .3);

      .nav-tabs {
        margin: 0 auto;
        padding-bottom: 0;
        max-width: 1230px;
      }
    }
  }
}

.cta-btn__link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.cta-btn__phone {

  @include respond-to(sm) {
    pointer-events: none;
  }
}

.hero__icons-container {
  display: flex;
  justify-content: center;
  margin-bottom: -190px;
  text-align: center;

  .hero__icons-list {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }

    > .icon__container {
      width: 100%;

      @include respond-to(xs) {
        width: 33.33%;
      }
    }
  }

  .icon__wrapper {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
  }

  .hero__icon {

    background: white;
    border-radius: 50%;
    border: 10px solid #8dd0b5;
  }

  p {
    @include font-size(2);
    width: 100%;
    color: #fff;

    @media only screen and (max-width: 767px) {
      margin-top: 30px;
      margin-bottom: 10px;
    }

    @include respond-to(sm) {
      @include font-size(2);
      white-space: nowrap;
    }
  }
}

/*
Theme Name: blank
Text Domain: blank
Author: Pace Developers
Version: 1.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
*/
/*--------------------------------------------------------------*/
.animated.delay-0-6s {
  -webkit-animation-delay: .6s;
  animation-delay: .6s; }

.animated.delay-0-8s {
  -webkit-animation-delay: .8s;
  animation-delay: .8s; }

.animated.delay-1-2s {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s; }

.animated.delay-1-4s {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s; }

.animated.delay-1-6s {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s; }

/* Make the element pulse (grow large and small slowly) */
/* Usage
    .myElement {
        animation: pulsate 1s ease-out;
        animation-iteration-count: infinite;
        opacity: 1;
    }
*/
@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0.0; }
  50% {
    opacity: 1.0; }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0.0; } }

/* Make the element's opacity pulse*/
/* Usage
    .myElement {
        animation: opacityPulse 1s ease-out;
        animation-iteration-count: infinite;
        opacity: 0;
    }
*/
@-webkit-keyframes opacityPulse {
  0% {
    opacity: 0.0; }
  50% {
    opacity: 1.0; }
  100% {
    opacity: 0.0; } }

.pulsate-css {
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0.0; }

@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?qarsie");
  src: url("../fonts/icomoon.eot?qarsie#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?qarsie") format("truetype"), url("../fonts/icomoon.woff?qarsie") format("woff"), url("../fonts/icomoon.svg?qarsie#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none;
  speak: none;
  /* Better Font Rendering =========== */ }

.icon-arrow:before {
  content: "\e900"; }

.icon-doc:before {
  content: "\e901"; }

/*--------------------------------------------------------------
1.0 Normalize
--------------------------------------------------------------*/
html {
  font-family: sans-serif;
  text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  margin: .67em 0;
  font-size: 2em; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sup {
  top: -.5em; }

sub {
  bottom: -.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  height: 0;
  box-sizing: content-box; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  color: inherit;
  font: inherit; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  padding: 0;
  box-sizing: border-box; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  box-sizing: content-box;
  -webkit-appearance: textfield; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  margin: 0 2px;
  padding: .35em .625em .75em;
  border: 1px solid #c0c0c0; }

legend {
  padding: 0;
  border: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

/*--------------------------------------------------------------
2.0 Base
--------------------------------------------------------------*/
/*
* Usage: https://smacss.com/book/type-base
**/
html {
  font-size: 10px;
  box-sizing: border-box; }

*,
*:before,
*:after {
  /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
  box-sizing: inherit; }

body {
  background: #fff;
  /* Fallback for when there is no custom background color defined. */ }

body,
button,
input,
select,
textarea {
  font-size: 1rem;
  color: #141413;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
  line-height: 1.5; }

body {
  font-size: 1rem !important; }

b,
strong {
  font-weight: 600; }

blockquote,
q {
  quotes: "" ""; }
  blockquote:before, blockquote:after,
  q:before,
  q:after {
    content: ""; }

hr {
  height: 1px;
  margin-bottom: 1.5em;
  border: 0;
  background-color: #beb7b3; }

img:not(.reset-height-image) {
  max-width: 100%;
  /* Adhere to container width. */
  height: auto;
  /* Make sure images are scaled correctly. */ }

label {
  font-weight: 600; }
  @media (max-width: 768px) {
    label {
      font-size: 1.8rem; } }
  @media (min-width: 768px) {
    label {
      font-size: 1.8rem; } }

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
  max-width: 100%; }

ul,
ol {
  margin: 1.5em 0;
  padding-left: 1.5em; }
  @media (max-width: 768px) {
    ul,
    ol {
      font-size: 1.8rem; } }
  @media (min-width: 768px) {
    ul,
    ol {
      font-size: 1.8rem; } }

ul {
  list-style: disc; }
  ul.red {
    padding-left: 0;
    list-style: none; }
    ul.red li {
      margin-left: .5em;
      padding-left: 1em;
      background: url("../img/bullet.png") center left no-repeat; }
      @media (min-width: 1290px) {
        ul.red li {
          display: inline-block; } }

ol {
  list-style: decimal; }
  .pdf ol {
    margin: .5em 0; }
    .pdf ol li {
      margin-top: .5em; }

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1.5em; }

dt {
  font-weight: bold; }

dd {
  margin: 0 1.5em 1.5em; }

table {
  width: 100%;
  margin: 0 0 1.5em; }
  @media (max-width: 768px) {
    table {
      font-size: 1.8rem; } }
  @media (min-width: 768px) {
    table {
      font-size: 1.8rem; } }
  table th {
    padding: 10px 20px; }
    @media (max-width: 992px) {
      table th {
        padding: 5px; } }
  table td {
    padding: 2px 20px; }
    @media (max-width: 992px) {
      table td {
        padding: 2px 15px; } }

a {
  color: #0f8b8d;
  font-weight: normal;
  text-decoration: underline; }
  a:visited {
    color: #0f8b8d; }
  a:hover, a:focus, a:active {
    color: #000; }
  a:focus {
    outline: thin dotted; }
  a:hover, a:active {
    outline: 0; }

.btn {
  padding: 8px 35px;
  border-radius: 0;
  text-decoration: none; }
  .btn, .btn:active, .btn.active, .btn:focus, .btn.focus, .btn:hover {
    text-shadow: none;
    box-shadow: none; }
  .btn-default {
    font-size: 1.75rem;
    transition: .2s background, .2s color, .2s border;
    border: 1px transparent solid;
    font-weight: 600;
    letter-spacing: 0.3em;
    text-transform: uppercase; }
    .btn-default, .btn-default:link, .btn-default:visited {
      background: #c00;
      color: #fff; }
    .btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default:active:hover, .btn-default:active:focus {
      border: 1px #c00 solid;
      background: #fff;
      color: #c00; }
  .btn-small {
    font-size: 1.28rem; }
  .btn-cta {
    margin-left: 35px;
    transition: .2s background, .2s color;
    border: 1px #fff solid;
    background: #0f8b8d;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase; }
    .btn-cta:hover {
      background: #fff;
      color: #0f8b8d; }

.red-arrow {
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: .14em;
  text-decoration: none; }
  .red-arrow, .red-arrow:visited {
    color: #cc0d0d; }
  .red-arrow:hover {
    text-decoration: none; }

p {
  margin: 1em 0; }
  @media (max-width: 768px) {
    p {
      font-size: 1.8rem; } }
  @media (min-width: 768px) {
    p {
      font-size: 1.8rem; } }

dfn,
cite,
em,
i {
  font-style: italic; }

blockquote {
  margin: 0 1.5em; }

address {
  margin: 0 0 1.5em; }

pre {
  font-size: 0.9375rem;
  max-width: 100%;
  margin-bottom: 1.6em;
  padding: 1.6em;
  background: #eee;
  font-family: "Courier 10 Pitch", Courier, monospace;
  line-height: 1.6;
  overflow: auto; }

code,
kbd,
tt,
var {
  font-size: 0.9375rem;
  font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace; }

abbr,
acronym {
  border-bottom: 1px dotted #666;
  cursor: help; }

mark,
ins {
  background: #fff9c0;
  text-decoration: none; }

big {
  font-size: 125%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: .5em;
  clear: both;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.5; }

h1,
.h1 {
  font-size: 3.5rem;
  margin: 0;
  font-weight: 300;
  line-height: 50px; }
  h1.lede,
  .h1.lede {
    margin-top: .5em;
    padding-bottom: .5em;
    border-bottom: 9px #c00 solid;
    line-height: 39px; }

h2 {
  font-size: 2.4rem;
  margin: .5em 0;
  font-weight: 600; }

button,
input[type="button"],
input[type="reset"] {
  padding: .6em 1em .4em;
  border: 1px solid;
  border-radius: 3px;
  border-color: #ccc #ccc #bbb;
  background: #e6e6e6;
  color: rgba(0, 0, 0, 0.8);
  line-height: 1;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5), inset 0 15px 17px rgba(255, 255, 255, 0.5), inset 0 -5px 12px rgba(0, 0, 0, 0.05); }
  @media (max-width: 768px) {
    button,
    input[type="button"],
    input[type="reset"] {
      font-size: 1.8rem; } }
  @media (min-width: 768px) {
    button,
    input[type="button"],
    input[type="reset"] {
      font-size: 1.8rem; } }
  button:hover,
  input[type="button"]:hover,
  input[type="reset"]:hover {
    border-color: #ccc #bbb #aaa;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.8), inset 0 15px 17px rgba(255, 255, 255, 0.8), inset 0 -5px 12px rgba(0, 0, 0, 0.02); }
  button:active, button:focus,
  input[type="button"]:active,
  input[type="button"]:focus,
  input[type="reset"]:active,
  input[type="reset"]:focus {
    border-color: #aaa #bbb #bbb;
    box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.5), inset 0 2px 5px rgba(0, 0, 0, 0.15); }

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="tel"],
textarea {
  border: 1px solid #ccc;
  border-radius: 0;
  color: #666; }
  input[type="text"]:focus,
  input[type="email"]:focus,
  input[type="url"]:focus,
  input[type="password"]:focus,
  input[type="search"]:focus,
  input[type="tel"]:focus,
  textarea:focus {
    color: #111; }

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"] {
  padding: 3px;
  padding-left: 10px; }

textarea {
  width: 100%;
  padding-left: 3px; }

.input-group-addon {
  border-radius: 0; }

/*--------------------------------------------------------------
3.0 Accessibility
--------------------------------------------------------------*/
.text--dark-blue {
  color: #174d7f; }

.text--light-blue {
  color: #1989c7; }

strong, b {
  font-weight: bold; }

.text--bold {
  font-weight: bold; }

.top-bar__logos-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  padding: 35px 0; }
  .top-bar__logos-container img {
    margin-bottom: 20px; }
  @media (min-width: 768px) {
    .top-bar__logos-container {
      justify-content: space-between; }
      .top-bar__logos-container img {
        margin-bottom: 0; } }

.results-page .hero__sub-heading {
  margin-bottom: 0; }

.banner-arrow__container {
  position: relative; }

.banner-arrow {
  position: absolute;
  bottom: -120px;
  left: 50%;
  transform: translate(-50%, 0); }
  .banner-arrow .pulsate-css {
    display: block;
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 1;
    width: 120px;
    height: 120px;
    border: 3px solid #017dc1;
    border-radius: 50%;
    z-index: 1; }
  .banner-arrow img {
    position: relative;
    z-index: 2; }

.hero__content {
  position: relative;
  padding: 100px 0;
  margin-bottom: 120px;
  background-image: url(/img/homeBanner1.png);
  background-size: cover;
  background-position: center; }

.hero__text-container h1, .hero__text-container p {
  color: white; }

.hero__heading {
  font-size: 6.4rem;
  line-height: 70px;
  font-weight: bold; }

.hero__sub-heading {
  font-size: 2.7rem;
  line-height: 30px;
  margin-top: 30px;
  margin-bottom: 50px; }
  .hero__sub-heading > span {
    font-size: 2.4rem; }

.hero__icons-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start; }

.hero__icon {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  width: 180px;
  height: 180px; }
  .hero__icon > img {
    margin: 0; }
  .hero__icon > p {
    font-size: 2.8rem;
    line-height: 32px;
    font-weight: 500;
    margin: 0; }

div.content {
  overflow: hidden; }

.section__introduction {
  padding-top: 160px; }

@media (min-width: 768px) {
  .introduction__text {
    margin-bottom: 0;
    font-size: 2rem; } }

.introduction__container {
  position: relative; }
  .introduction__container:before, .introduction__container:after {
    position: absolute;
    content: ''; }
  @media (min-width: 992px) {
    .introduction__container:after {
      height: calc(100% + 150px);
      width: 320px;
      background-image: url(/img/bc_map_no_light.png);
      background-size: contain;
      background-repeat: no-repeat;
      right: -148px;
      top: -91px; } }
  .introduction__container.light-on .introduction__wrapper:after, .introduction__container.light-on .introduction__wrapper:before {
    z-index: 3; }

.introduction__wrapper {
  padding: 10px;
  border: 10px solid #0c548b; }
  @media (min-width: 992px) {
    .introduction__wrapper {
      padding: 90px 180px 42px 90px; } }

.introduction__heading {
  font-size: 3rem;
  line-height: 42px;
  color: #0c548b;
  font-weight: bold; }

.introduction__sub-heading {
  font-size: 1.8rem;
  line-height: 30px; }

.section__divider {
  margin-top: 70px;
  padding-bottom: 130px;
  font-weight: bold; }
  .section__divider#divider1 {
    padding-bottom: 250px; }
  .section__divider-container > p {
    margin-top: 0;
    margin-bottom: 50px;
    color: #4f4f4f;
    font-size: 24px; }
  .section__divider-navigation p {
    color: #017dc1;
    font-size: 36px; }

.section__solution {
  position: relative; }
  .section__solution .cloud {
    display: none;
    position: absolute; }
    @media (min-width: 1290px) {
      .section__solution .cloud {
        display: block; } }
    .section__solution .cloud#cloud1 {
      top: -250px;
      left: -80px; }
    .section__solution .cloud#cloud2 {
      top: -130px;
      right: 280px; }
    .section__solution .cloud#cloud3 {
      top: 0;
      right: 0; }
    .section__solution .cloud#cloud4 {
      left: 50px;
      bottom: 150px; }
    .section__solution .cloud#cloud5 {
      bottom: 0;
      right: 100px; }
    .section__solution .cloud#cloud6 {
      left: -25px;
      bottom: -40px; }
    .section__solution .cloud#cloud7 {
      left: 560px;
      bottom: -100px; }
  .section__solution__start--text-container {
    position: relative; }
    .section__solution__start--text-container:before {
      content: '';
      position: absolute;
      width: 68px;
      height: 68px;
      background-image: url("/img/Rectangle_image.png");
      background-size: auto;
      background-repeat: no-repeat;
      top: 15px;
      left: -80px; }
    .section__solution__start--text-container p {
      font-size: 2.4rem;
      margin-bottom: 0; }

.nice-title-with-icon {
  font-size: 3.5rem;
  line-height: 50px;
  color: #1875ba; }
  @media (min-width: 992px) {
    .nice-title-with-icon {
      font-size: 6.5rem;
      line-height: 70px; } }

@media only screen and (max-width: 992px) {
  .custom-br {
    display: none; } }

.question-list__container > .question-list .question-list__item {
  padding-top: 100px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center; }
  @media (min-width: 992px) {
    .question-list__container > .question-list .question-list__item {
      padding-top: 0; } }
  .question-list__container > .question-list .question-list__item p {
    flex: 1; }

.question-list__heading {
  position: relative;
  padding-left: 60px;
  padding-bottom: 30px; }
  @media (min-width: 992px) {
    .question-list__heading {
      padding-left: 90px; } }
  .question-list__heading:before {
    content: attr(data-question-num);
    position: absolute;
    left: 0;
    top: 0;
    font-size: 5rem;
    line-height: 49.08px;
    color: #174d7f;
    font-weight: bold; }
    @media (min-width: 992px) {
      .question-list__heading:before {
        top: 12px;
        font-size: 8.588rem; } }
  .question-list__heading:after {
    content: '';
    position: absolute;
    top: 36px;
    left: 32px;
    width: 7px;
    height: 7px;
    background-color: #174d7f; }
    @media (min-width: 992px) {
      .question-list__heading:after {
        top: 59px;
        left: 50px;
        width: 11px;
        height: 11px; } }
  .question-list__heading h2, .question-list__heading p {
    margin: 0;
    font-weight: bold; }
  .question-list__heading h2 {
    font-size: 2rem;
    color: #1875ba; }
    @media (min-width: 992px) {
      .question-list__heading h2 {
        font-size: 2.6rem; } }
  .question-list__heading p {
    font-size: 1.8rem;
    color: #5ac399; }
    @media (min-width: 992px) {
      .question-list__heading p {
        font-size: 2rem; } }

.question-list__choices-container {
  margin-top: 30px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between; }
  .question-list__choices-container > .choice {
    opacity: 0;
    width: 100%;
    padding-left: 105px;
    padding-right: 10px;
    border: 3px solid lightgray;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    position: relative;
    min-height: 150px;
    background: rgba(255, 255, 255, 0.6); }
    .question-list__choices-container > .choice:before {
      content: '';
      position: absolute;
      left: 10px;
      bottom: 0;
      width: 75px;
      height: 105px;
      background-image: url("/img/light.png");
      background-size: auto;
      background-repeat: no-repeat; }
    @media (min-width: 992px) {
      .question-list__choices-container > .choice {
        overflow: hidden;
        min-height: inherit;
        padding-top: 40px;
        padding-bottom: 40px; }
        .question-list__choices-container > .choice:before {
          bottom: -26px;
          width: 75px;
          height: 90%;
          background-image: url(/img/light.png);
          background-size: auto;
          background-repeat: no-repeat;
          background-position: top; } }
    .question-list__choices-container > .choice:hover {
      cursor: pointer; }
    .question-list__choices-container > .choice.active {
      border-color: #5ac399;
      background-color: #1875ba;
      color: white;
      cursor: pointer; }
      .question-list__choices-container > .choice.active:before {
        background-image: url("/img/light_hover.png"); }
    @media (min-width: 992px) {
      .question-list__choices-container > .choice {
        width: calc(50% - 15px); } }
    .question-list__choices-container > .choice p {
      font-size: 1.6rem;
      line-height: 21px;
      margin: 0; }

.none-btn {
  color: #017dc1 !important;
  text-decoration: none !important;
  font-size: 36px;
  font-weight: bold;
  outline: none !important; }
  .none-btn:hover, .none-btn:active, .none-btn:visited {
    color: #017dc1;
    text-decoration: none; }

.btn--next-section {
  cursor: pointer; }

.btn--next-section:not(.none-btn):not(.nav-link) {
  background-color: #ef962d;
  color: white;
  border-radius: 10px;
  margin-top: 5px;
  font-size: 2.6rem;
  font-weight: bold; }
  .btn--next-section:not(.none-btn):not(.nav-link):hover {
    background-color: #1875ba;
    color: white; }
  .btn--next-section:not(.none-btn):not(.nav-link):visited {
    color: white; }

.nav-link {
  cursor: pointer; }

.dot-nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 50%;
  right: -95px;
  transform: translate(0, -50%);
  opacity: 0;
  transition: all .6s ease-in-out; }
  @media (min-width: 992px) {
    .dot-nav.expand {
      right: 0; } }
  .dot-nav.active {
    opacity: 1; }
  .dot-nav > li {
    height: 12px; }
    .dot-nav > li a.anchor-link, .dot-nav > li span {
      display: inline-block; }
    .dot-nav > li:not(:last-child) {
      margin-bottom: 30px; }
    .dot-nav > li span {
      padding-right: 20px;
      padding-left: 5px;
      font-size: 1.6rem; }
  .dot-nav .anchor-link {
    width: 12px;
    height: 12px;
    border: 2px solid #1875ba;
    border-radius: 50%;
    outline: none; }
    .dot-nav .anchor-link.is-selected {
      background-color: #1875ba; }

#quest-list__choices2 .choice {
  width: 100%; }
  @media (min-width: 992px) {
    #quest-list__choices2 .choice {
      width: calc(33.33% - 15px); } }

.questions-content {
  display: flex;
  flex-flow: row wrap;
  position: relative; }
  @media (min-width: 992px) {
    .questions-content:before {
      content: '';
      position: absolute;
      width: 740px;
      height: 100%;
      top: 0;
      left: 0;
      background-image: url(/img/map.jpg);
      background-size: contain;
      background-repeat: no-repeat; } }
  @media only screen and (max-width: 1480px) {
    .questions-content:before {
      opacity: .2; } }
  .questions-content > div {
    width: 100%; }
    @media (min-width: 992px) {
      .questions-content > div {
        width: calc(100% - 752px); }
        .questions-content > div.question-list__container {
          width: 752px;
          position: relative;
          left: 50px; } }

.section__form {
  padding: 50px 0 320px;
  background-image: url(/img/footer_background.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom; }

.form__container h2 {
  text-align: center;
  color: #174d7f;
  font-weight: bold; }
  @media (min-width: 992px) {
    .form__container h2 {
      font-size: 4.588rem; } }

.form__container .form-control {
  height: 40px;
  border-radius: 5px; }

.form__container .result-label {
  color: #4f4f4f;
  font-weight: normal; }

.btn-submit {
  display: block;
  width: 100%;
  color: white;
  background-color: #ef962d;
  font-weight: bold;
  padding: 10px 0;
  font-size: 20px;
  border-radius: 10px;
  margin-top: 50px; }
  @media (min-width: 992px) {
    .btn-submit {
      font-size: 26px; } }
  .btn-submit:hover {
    color: white;
    background-color: #1875ba; }

.form-label {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: pointer; }

.selected-choices__container input {
  display: none; }

.footer__container {
  padding: 56px 0;
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center; }
  @media (min-width: 992px) {
    .footer__container {
      text-align: left;
      justify-content: space-between; } }
  .footer__container p {
    margin: 0; }
  .footer__container div {
    margin-top: 30px; }
    @media (min-width: 992px) {
      .footer__container div {
        margin-top: 0; } }

.results-page .nav-tabs {
  padding-bottom: 25px;
  border-bottom: none; }
  .results-page .nav-tabs > li:not(.nav-item--get-started) {
    width: 210px; }
    @media (min-width: 768px) {
      .results-page .nav-tabs > li:not(.nav-item--get-started) {
        margin-right: 20px; } }
    .results-page .nav-tabs > li:not(.nav-item--get-started):hover {
      background: transparent; }
    .results-page .nav-tabs > li:not(.nav-item--get-started).active > a {
      color: #1989c7;
      border-bottom: 4px solid #1989c7; }
    .results-page .nav-tabs > li:not(.nav-item--get-started) > a {
      color: #174d7f;
      padding-right: 0;
      padding-left: 0;
      padding-bottom: 15px;
      border: none;
      border-bottom: 4px solid lightgray; }
  .results-page .nav-tabs > li.nav-item--get-started {
    text-decoration: none; }
    .results-page .nav-tabs > li.nav-item--get-started.active > a {
      background-color: #1989c7;
      border: 1px solid #1989c7 !important; }
    .results-page .nav-tabs > li.nav-item--get-started > a {
      background-color: #ef962d;
      color: white;
      border: 1px solid #ef962d !important;
      border-radius: 8px; }
      .results-page .nav-tabs > li.nav-item--get-started > a:hover {
        background-color: #1989c7;
        border: 1px solid #1989c7 !important; }
  .results-page .nav-tabs > li > a {
    font-size: 3rem;
    font-weight: bold;
    text-decoration: none;
    outline: none; }

.results-page .nav-tabs > li.active > a, .results-page .nav-tabs > li.active > a:hover, .results-page .nav-tabs > li.active > a:focus {
  border: none;
  text-decoration: none;
  border-bottom: 4px solid #1989c7; }

.results-page .nav > li > a:hover, .results-page .nav > li > a:focus {
  text-decoration: none;
  background-color: transparent; }

.results-page .results__heading {
  font-size: 4.8rem;
  font-weight: bold;
  margin-bottom: 0; }

.results-page .results__sub-heading {
  margin-top: 0;
  font-size: 2.4rem; }

.results-page .results__list-item__heading {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 25px; }
  @media (min-width: 768px) {
    .results-page .results__list-item__heading {
      font-size: 2.4rem; } }

.results-page .heading--checkmark {
  position: relative;
  padding-left: 50px; }
  .results-page .heading--checkmark > img {
    position: absolute;
    left: 0;
    top: 0; }

.results-page .results__list > .results__list-item__row {
  padding: 40px 0; }
  .results-page .results__list > .results__list-item__row:nth-child(odd) {
    background: #f8f8f8; }

.results-page #results-list1 p {
  margin: 0; }

.results-page #results-list2 h3 {
  font-size: 3rem; }

.results-page #results-list2 h4 {
  font-weight: bold;
  font-size: 2.4rem; }

.results-page #results-list2 p, .results-page #results-list2 ul {
  margin: 0; }

.results-page #results-list2 .results__list-item__row > .container {
  display: flex;
  flex-flow: row wrap; }
  .results-page #results-list2 .results__list-item__row > .container > div {
    display: flex; }
    .results-page #results-list2 .results__list-item__row > .container > div:first-child {
      flex-flow: row wrap;
      align-item: center; }
    .results-page #results-list2 .results__list-item__row > .container > div:last-child {
      flex-flow: column nowrap;
      justify-content: center; }

.results-page #results-list2 .results__list-item__row .download-container {
  margin-top: 20px; }
  @media (min-width: 768px) {
    .results-page #results-list2 .results__list-item__row .download-container {
      margin-top: 0; } }
  .results-page #results-list2 .results__list-item__row .download-container > p {
    margin-bottom: 20px;
    padding-bottom: 20px; }
    .results-page #results-list2 .results__list-item__row .download-container > p:first-child {
      border-bottom: 1px solid #e7e7e7; }
    .results-page #results-list2 .results__list-item__row .download-container > p a {
      text-decoration: none;
      color: #141413; }
      .results-page #results-list2 .results__list-item__row .download-container > p a:hover {
        color: #1989c7; }
    .results-page #results-list2 .results__list-item__row .download-container > p img {
      margin-right: 20px; }

.results-page .btn--regular {
  margin: 60px 0; }

#partners ul.custom-list--green > li {
  margin-bottom: 10px; }

ul.custom-list--green {
  padding-left: 0;
  list-style-type: none; }
  ul.custom-list--green > li {
    position: relative;
    padding-left: 10px; }
    ul.custom-list--green > li:before {
      content: "";
      position: absolute;
      left: 0;
      top: 12px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #5ac399; }

ul.custom-list p {
  margin: 0; }

ul.custom-list--number {
  margin-top: 0; }
  ul.custom-list--number > li {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 30px; }
    ul.custom-list--number > li > div {
      margin-right: 20px; }
      ul.custom-list--number > li > div > p {
        width: 60px;
        height: 60px;
        background: #1989c7;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 3.4rem;
        font-weight: bold; }

.did-you-know__container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  padding: 50px 0;
  margin-top: 50px;
  margin-bottom: 50px; }
  .did-you-know__container h3, .did-you-know__container p {
    margin: 0;
    text-align: center; }
    @media (min-width: 768px) {
      .did-you-know__container h3, .did-you-know__container p {
        text-align: left; } }
  @media (min-width: 768px) {
    .did-you-know__container h3 {
      margin-right: 50px; } }
  .did-you-know__container p {
    margin-top: 5px; }
  .did-you-know__container#did-you-know__container2 {
    flex-flow: row wrap; }
    .did-you-know__container#did-you-know__container2 h3, .did-you-know__container#did-you-know__container2 p {
      text-align: left; }
    .did-you-know__container#did-you-know__container2 h3 {
      width: 300px; }
    .did-you-know__container#did-you-know__container2 > div > div {
      margin: 10px 0;
      padding: 10px 0; }
      .did-you-know__container#did-you-know__container2 > div > div:first-child {
        border-bottom: 1px solid #ebebeb; }
    @media (min-width: 768px) {
      .did-you-know__container#did-you-know__container2 {
        flex-flow: row nowrap; } }

a.text--light-blue {
  color: #1989c7; }

.cta-banner {
  padding: 80px 0;
  background-color: #1989c7; }
  .cta-banner h2, .cta-banner p, .cta-banner a {
    color: white; }
  .cta-banner h2 {
    font-size: 4.5rem;
    margin-bottom: 70px; }
  .cta-banner p {
    font-weight: bold;
    color: #a7d5ef; }
  .cta-banner a {
    font-size: 2.3rem; }
    .cta-banner a.cta-btn__phone-number {
      text-decoration: none;
      font-size: 3.4rem; }
  @media (min-width: 992px) {
    .cta-banner > .container {
      width: 990px; } }
  .cta-banner .cta-btn {
    border: 5px solid #5ac399;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    padding-left: 30px;
    transition: all .3s linear; }
    @media (min-width: 992px) {
      .cta-banner .cta-btn {
        margin-bottom: 0; } }
    @media (min-width: 768px) {
      .cta-banner .cta-btn {
        width: 450px;
        padding-left: 175px; }
        .cta-banner .cta-btn:before {
          content: '';
          bottom: 0;
          position: absolute;
          height: calc(100% + 18px);
          background-size: contain;
          background-repeat: no-repeat; }
        .cta-banner .cta-btn__phone {
          margin-right: 30px;
          padding-left: 150px; }
          .cta-banner .cta-btn__phone:before {
            left: 20px;
            width: 115px;
            background-image: url(/img/phoneIcon.png); }
        .cta-banner .cta-btn__email:before {
          bottom: 0;
          left: 0;
          width: 166px;
          background-image: url(/img/emailIcon.png); } }
    .cta-banner .cta-btn__container {
      display: flex;
      flex-flow: row wrap; }
    .cta-banner .cta-btn:hover {
      background-color: #19699d; }

#resultsTab > li {
  margin-bottom: 20px; }

.btn--regular {
  background-color: #ef962d;
  color: white;
  border-radius: 10px;
  padding: 10px 20px;
  margin-top: 5px;
  font-size: 2.6rem;
  font-weight: bold;
  text-decoration: none !important;
  display: inline-block; }
  .btn--regular:hover, .btn--regular:visited, .btn--regular:active {
    color: white;
    text-decoration: none; }
  .btn--regular:hover {
    background-color: #1989c7; }

.animated {
  opacity: 0; }
  .animated.zoomIn {
    opacity: 1; }

#Table_01 {
  width: auto;
  margin: 0 auto; }
  #Table_01 td, #Table_01 th, #Table_01 tr {
    vertical-align: inherit;
    padding: 0; }
  #Table_01 tr {
    padding: 0; }

.on-mobile {
  display: block;
  margin-bottom: 40px; }
  @media (min-width: 992px) {
    .on-mobile {
      display: none; } }

.on-desktop {
  display: none;
  margin-bottom: 40px; }
  @media (min-width: 992px) {
    .on-desktop {
      display: block; } }

.animated.delay-0-1s {
  animation-delay: .1s; }

.scrollto-animated-element {
  opacity: 0; }

.animated.delay-0-2s {
  animation-delay: .2s; }

.animated.delay-0-3s {
  animation-delay: .3s; }

.animated.delay-0-4s {
  animation-delay: .4s; }

.animated.delay-0-5s {
  animation-delay: .5s; }

.animated.delay-0-6s {
  animation-delay: .6s; }

.animated.delay-0-7s {
  animation-delay: .7s; }

.animated.delay-0-8s {
  animation-delay: .8s; }

.animated.delay-0-9s {
  animation-delay: .9s; }

.animated.delay-1-1s {
  animation-delay: 1.1s; }

.animated.delay-1-2s {
  animation-delay: 1.2s; }

.animated.delay-1-3s {
  animation-delay: 1.3s; }

.animated.delay-1-4s {
  animation-delay: 1.4s; }

.animated.delay-1-5s {
  animation-delay: 1.5s; }

.animated.delay-1-6s {
  animation-delay: 1.6s; }

.animated.delay-1-7s {
  animation-delay: 1.7s; }

.animated.delay-1-8s {
  animation-delay: 1.8s; }

.animated.delay-1-9s {
  animation-delay: 1.9s; }

.animated.delay-2s {
  animation-delay: 2s; }

.animated.delay-2-1s {
  animation-delay: 2.1s; }

.animated.delay-2-2s {
  animation-delay: 2.2s; }

.animated.delay-2-3s {
  animation-delay: 2.3s; }

.animated.delay-2-4s {
  animation-delay: 2.4s; }

.animated.delay-2-5s {
  animation-delay: 2.5s; }

.animated.delay-2-6s {
  animation-delay: 2.6s; }

.animated.delay-2-7s {
  animation-delay: 2.7s; }

.animated.delay-2-8s {
  animation-delay: 2.8s; }

.animated.delay-2-9s {
  animation-delay: 2.9s; }

.lamp__container {
  position: absolute;
  left: 0;
  height: calc(100% + 30px);
  bottom: 10px;
  z-index: 100;
  width: 100%; }
  @media only screen and (max-width: 767px) {
    .lamp__container {
      display: none; } }

.lamps {
  position: relative;
  height: 100%;
  left: 40px;
  bottom: 10px; }
  @media only screen and (max-width: 767px) {
    .lamps {
      display: none; } }

.lamp {
  position: absolute;
  height: 100%; }
  .lamp img {
    width: auto;
    display: none; }
    @media (min-width: 768px) {
      .lamp img {
        height: 572px; } }
    @media (min-width: 992px) {
      .lamp img {
        display: block; } }
    @media (min-width: 1290px) {
      .lamp img {
        height: 458px; } }

.lamp-on {
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: all .3s ease-in; }
  @media (min-width: 768px) {
    .lamp-on img {
      height: 572px; } }
  @media (min-width: 1290px) {
    .lamp-on img {
      height: 458px; } }
  .lamp-on.active {
    height: 100%; }
  .lamp-on .overlay {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0; }

.bc-map-overlay {
  position: absolute;
  content: '';
  height: 0;
  width: 320px;
  right: -148px;
  top: -91px;
  overflow: hidden;
  z-index: 3; }
  .bc-map-overlay::before {
    position: absolute;
    content: '';
    width: 320px;
    height: 578px;
    background-image: url(/img/BC_map.png);
    background-size: contain;
    background-repeat: no-repeat;
    left: 0;
    top: 0;
    z-index: 3; }
  .bc-map-overlay.active {
    height: calc(100% + 150px); }

@media (min-width: 992px) {
  .sticky-nav.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: white;
    z-index: 100;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.3); }
    .sticky-nav.sticky .nav-tabs {
      margin: 0 auto;
      padding-bottom: 0;
      max-width: 1230px; } }

.cta-btn__link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

@media (min-width: 992px) {
  .cta-btn__phone {
    pointer-events: none; } }

.hero__icons-container {
  display: flex;
  justify-content: center;
  margin-bottom: -190px;
  text-align: center; }
  .hero__icons-container .hero__icons-list {
    display: flex;
    justify-content: center;
    flex-flow: row wrap; }
    @media only screen and (max-width: 767px) {
      .hero__icons-container .hero__icons-list {
        width: 100%; } }
    .hero__icons-container .hero__icons-list > .icon__container {
      width: 100%; }
      @media (min-width: 768px) {
        .hero__icons-container .hero__icons-list > .icon__container {
          width: 33.33%; } }
  .hero__icons-container .icon__wrapper {
    display: flex;
    justify-content: center;
    flex-flow: row wrap; }
  .hero__icons-container .hero__icon {
    background: white;
    border-radius: 50%;
    border: 10px solid #8dd0b5; }
  .hero__icons-container p {
    font-size: 2rem;
    width: 100%;
    color: #fff; }
    @media only screen and (max-width: 767px) {
      .hero__icons-container p {
        margin-top: 30px;
        margin-bottom: 10px; } }
    @media (min-width: 992px) {
      .hero__icons-container p {
        font-size: 2rem;
        white-space: nowrap; } }

/*--------------------------------------------------------------
4.0 Layout
--------------------------------------------------------------*/
/*
* Usage: https://smacss.com/book/type-layout
**/
.text--center {
  text-align: center; }

/*--------------------------------------------------------------
5.0 Misc
--------------------------------------------------------------*/
/*
* Usage: Utility classes that do not fit anywhere in particular into SMACSS (https://smacss.com/book/)
**/
/* Text meant only for screen readers. */
.screen-reader-text {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px); }
  .screen-reader-text:hover, .screen-reader-text:active, .screen-reader-text:focus {
    font-size: 0.875rem;
    display: block;
    top: 5px;
    left: 5px;
    width: auto;
    height: auto;
    padding: 15px 23px 14px;
    border-radius: 3px;
    background-color: #f1f1f1;
    color: #141413;
    font-weight: bold;
    line-height: normal;
    text-decoration: none;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    z-index: 100000;
    /* Above WP toolbar. */
    clip: auto !important; }

.clear:before,
.clear:after {
  content: "";
  display: table; }

.clear:after {
  clear: both; }

/*--------------------------------------------------------------
6.0 Shaaaaaaaaaaaaame
--------------------------------------------------------------*/
/*
* Usage: Anything you aren't proud of, or hope/plan to fix later. Hopefully empty, but useful for identifying pain points when it's not.
**/
/*--------------------------------------------------------------
7.0 Overriding Bootstrap Styles
--------------------------------------------------------------*/

.animated.delay-0-6s {
    -webkit-animation-delay: .6s;
    animation-delay: .6s;
}
.animated.delay-0-8s {
    -webkit-animation-delay: .8s;
    animation-delay: .8s;
}
.animated.delay-1-2s {
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s;
}
.animated.delay-1-4s {
    -webkit-animation-delay: 1.4s;
    animation-delay: 1.4s;
}
.animated.delay-1-6s {
    -webkit-animation-delay: 1.6s;
    animation-delay: 1.6s;
}

/* Make the element pulse (grow large and small slowly) */
/* Usage
    .myElement {
        animation: pulsate 1s ease-out;
        animation-iteration-count: infinite;
        opacity: 1;
    }
*/
@-webkit-keyframes pulsate {
    0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
}

/* Make the element's opacity pulse*/
/* Usage
    .myElement {
        animation: opacityPulse 1s ease-out;
        animation-iteration-count: infinite;
        opacity: 0;
    }
*/
@-webkit-keyframes opacityPulse {
    0% {opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {opacity: 0.0;}
}

.pulsate-css {
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0.0;
}


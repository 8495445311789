ul,
ol {
  @include font-base-size();
  margin: 1.5em 0;
  padding-left: 1.5em;
}

ul {
  list-style: disc;

  &.red {
    padding-left: 0;
    list-style: none;

    li {
      margin-left: .5em;
      padding-left: 1em;
      background: url("../img/bullet.png") center left no-repeat;

      @include respond-to(md) {
        display: inline-block;
      }
    }
  }
}

ol {
  list-style: decimal;

  .pdf & {
    margin: .5em 0;

    li {
      margin-top: .5em;
    }
  }
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1.5em;
}

dt {
  font-weight: bold;
}

dd {
  margin: 0 1.5em 1.5em;
}

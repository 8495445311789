a {
  color: $color-link;
  font-weight: normal;
  text-decoration: underline;

  &:visited {
    color: $color-link-visited;
  }

  &:hover,
  &:focus,
  &:active {
    color: $color-link-hover;
  }

  &:focus {
    outline: thin dotted;
  }

  &:hover,
  &:active {
    outline: 0;
  }
}

.btn {
  padding: 8px 35px;
  border-radius: 0;
  text-decoration: none;

  &,
  &:active,
  &.active,
  &:focus,
  &.focus,
  &:hover {
    text-shadow: none;
    box-shadow: none;
  }

  &-default {
    @include font-size(1.75);
    transition: .2s background, .2s color, .2s border;
    border: 1px transparent solid;
    font-weight: 600;
    letter-spacing: 0.3em;
    text-transform: uppercase;

    &,
    &:link,
    &:visited {
      background: $color-brand-red;
      color: #fff;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:hover,
    &:active:focus {
      border: 1px $color-brand-red solid;
      background: #fff;
      color: $color-brand-red;
    }
  }

  &-small {
    @include font-size(1.28);
  }

  &-cta {
    margin-left: 35px;
    transition: .2s background, .2s color;
    border: 1px #fff solid;
    background: $color-brand-green;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;

    &:hover {
      background: #fff;
      color: $color-brand-green;
    }
  }
}

.red-arrow {
  @include font-size(1.3);
  font-weight: bold;
  letter-spacing: .14em;
  text-decoration: none;

  &,
  &:visited {
    color: #cc0d0d;
  }

  &:hover {
    text-decoration: none;
  }
}
